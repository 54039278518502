<template>
     <article>
          <v-container class="my-5" :key="this.$route.params.slug+'-'+this.$i18n.locale">
              <single-page :key="this.$route.params.slug+'-'+this.$i18n.locale" :slug="this.$route.params.slug"></single-page>
          </v-container>
     </article>
</template>

<script>
import singlePage from "@/components/Pages/single";
export default {
  name: 'App',
  components:{
    'single-page': singlePage
  },
  data: () => ({
    
  }),
};
</script>