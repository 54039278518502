<template>
     <v-row>
         <v-col v-if="Object.keys(errors).length">
              <v-alert type="error" v-if="Object.keys(errors).length">
                    {{message}}<br>
                    <ul>
                    <li v-for="(err,field) in errors" :key="field">
                         <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                    </li>  
                    </ul>
               </v-alert>
               <v-alert type="primary" v-else>
                    {{message}}
               </v-alert>
         </v-col>
         <v-col v-if="page">
            <v-card :loading="loading">
                <v-card-title primary-title>
                  <h2>{{page.title}}</h2>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div v-html="page.text"></div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
         </v-col>
     </v-row>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: 'pageSingle',
  props:{
    slug: {
         type: String,
         required: true
    }
  },
  computed: {
     ...mapGetters({
          page: 'pages/page', 
          errors: 'pages/errors', 
          message: 'pages/message', 
          loading: 'loading/loading'
      }),
  },
  mounted(){
      this.$store.dispatch('pages/single',this.slug)
  }
};
</script>